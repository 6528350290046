import React from 'react';
import { graphql, Link } from 'gatsby';
import Image from 'gatsby-image';
import { RichText } from 'gatsby-theme-q3/src/components';
import { get } from 'lodash';
import {
  Box,
  Button,
  Container,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SEOHelmet from '../components/SEOHelmet';

const useStyles = makeStyles((theme) => ({
  blog: {
    '& li': {
      display: 'block',
      position: 'relative',
    },
    '& li > div': {
      position: 'absolute',
      top: '12px',
    },
    '& li p': {
      marginLeft: '1.5rem',
    },
  },
  root: {
    '& h1, & h2': {
      fontSize: '1.5rem',
    },
  },
  btn: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  icon: {
    marginRight: '0.25rem',
  },
}));

const Blog = ({ data }) => {
  const { featuredImage, article, title } =
    data.contentfulBlogs;
  const cls = useStyles();

  return (
    <Container className={cls.blog}>
      <SEOHelmet title={title} />
      <Button
        to="/blog"
        component={Link}
        className={cls.btn}
      >
        <ArrowBackIcon className={cls.icon} /> Back to blog
      </Button>
      <Box
        bgcolor="background.paper"
        p={{ xs: 2, sm: 2, md: 4, lg: 5, xl: 5 }}
        mb={4}
      >
        <Typography variant="h1" gutterBottom>
          {title}
        </Typography>
        <Image
          fluid={get(featuredImage, 'fluid')}
          alt={get(featuredImage, 'title', title)}
          style={{
            width: '100%',
            height: '300px',
            margin: '1rem 0',
          }}
        />
        <Box className={cls.root} mt={3}>
          <RichText
            json={JSON.parse(get(article, 'raw'))}
          />
        </Box>
      </Box>
    </Container>
  );
};

export const query = graphql`
  query getBlogById($contentful_id: String) {
    contentfulBlogs(contentful_id: { eq: $contentful_id }) {
      featuredImage {
        description
        fluid {
          srcSet
        }
        title
      }
      published
      title
      article {
        raw
      }
    }
  }
`;

export default Blog;
