import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const SEOHelmet = ({ title, description }) => (
  <Helmet>
    {title && <title>{title}</title>}
    {description && (
      <meta name="description" content={description} />
    )}
  </Helmet>
);

SEOHelmet.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default SEOHelmet;
